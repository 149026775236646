// ChatApp.jsx
import React, { useState, useEffect, useRef } from "react";
import ReactGA from "react-ga4";
import Header from "./Header";
import Spinner from "./Spinner";
import { ErrorBoundary } from "react-error-boundary";
import { parse } from "stacktrace-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faMicrophone,
  faComments,
  faGlobe,
  faShareAlt,
  faPlus,
  faSun,
  faMoon,
  faListUl,
  faVolumeHigh,
  faChevronLeft,
  faChevronRight,
  faPlay,
  faPause,
  faStop,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";

import useSpeechRecognition from "../hooks/useSpeechRecognition";

import Markdown from "markdown-to-jsx";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  atomOneDark,
  github,
} from "react-syntax-highlighter/dist/esm/styles/hljs";

// ----------------------------------------------------
// 1) Enhanced function to fix blank lines after "1.", "2.", etc.
// function fixListLineBreaks(md) {
//   if (!md) return "";

//   // Split into lines
//   const lines = md.split("\n");
//   const result = [];
//   let inList = false;
//   let listNumber = 1;

//   for (let i = 0; i < lines.length; i++) {
//     const line = lines[i].trim();

//     // Check if this is a list item
//     const isListItem = /^\d+\.\s/.test(line);

//     if (isListItem) {
//       // Extract content after the number
//       const content = line.replace(/^\d+\.\s/, "").trim();

//       if (!inList) {
//         // Start of a new list
//         inList = true;
//         listNumber = 1;
//         result.push(""); // Add blank line before list
//       }

//       // Add the list item with correct numbering
//       result.push(`${listNumber}. ${content}`);
//       listNumber++;
//     } else {
//       if (inList) {
//         // End of list
//         inList = false;
//         result.push(""); // Add blank line after list
//       }
//       result.push(line);
//     }
//   }

//   return result.join("\n").trim();
// }

// Pulsing mic button
const micButtonStyle = (isListening) => ({
  position: "absolute",
  right: "10px",
  top: "10px",
  zIndex: 2,
  backgroundColor: "#333",
  color: "#fff",
  border: "none",
  cursor: "pointer",
  padding: "8px 12px",
  borderRadius: "4px",
  animation: isListening ? "micPulse 1.3s infinite" : "none",
});

/**
 * Plain Markdown renderer for the assistant response.
 * It highlights code blocks but does NOT break text into individual words.
 */
function MarkdownView({ text, isDarkMode }) {
  const codeTheme = isDarkMode ? atomOneDark : github;

  const options = {
    overrides: {
      code: {
        component: ({ children, className }) => {
          const match = /language-(\w+)/.exec(className || "");
          const isInline = !match;
          return isInline ? (
            <code className={className}>{children}</code>
          ) : (
            <SyntaxHighlighter
              style={codeTheme}
              language={match[1]}
              PreTag="div"
            >
              {String(children).replace(/\n$/, "")}
            </SyntaxHighlighter>
          );
        },
      },
      ol: {
        component: ({ children, ...props }) => (
          <ol className="numbered-list" {...props}>
            {children}
          </ol>
        ),
      },
      ul: {
        component: ({ children, ...props }) => (
          <ul className="bulleted-list" {...props}>
            {children}
          </ul>
        ),
      },
      li: {
        component: ({ children, ...props }) => (
          <li className="list-item" {...props}>
            {children}
          </li>
        ),
      },
    },
  };

  return (
    <div className="markdown-content" style={{ width: "100%" }}>
      <Markdown options={options} className="markdown-body">
        {text}
      </Markdown>
      <style>{`
        .markdown-body {
          font-family: Söhne, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;
          font-size: 18px;
          line-height: 1.5;
          word-wrap: break-word;
        }
        .markdown-body *,
        .markdown-body p,
        .markdown-body li,
        .markdown-body ul,
        .markdown-body ol {
          font-size: inherit;
        }
        .markdown-body h1,
        .markdown-body h2,
        .markdown-body h3,
        .markdown-body h4 {
          font-family: Söhne, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;
          font-weight: 600;
          margin: 1.5em 0 0.5em;
        }
        .markdown-body p {
          margin: 1.25em 0;
        }
        .numbered-list {
          list-style-type: decimal !important;
          margin: 1em 0;
          padding-left: 2.5em;
        }
        .numbered-list li {
          list-style: inherit !important;
          position: relative;
          display: list-item;
        }
        .numbered-list li:before {
          content: none !important;
        }
        .bulleted-list {
          list-style: disc;
          margin: 1em 0;
          padding-left: 2.5em;
        }
        .list-item {
          margin: 0.5em 0;
          padding: 0;
        }
      `}</style>
    </div>
  );
}

const BotReply = ({ text, isDarkMode }) => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const utterRef = useRef(null);

  // 2) Clean the text to remove extra blank lines after "1." etc.
  const cleanedText = text || "";

  const handleSpeak = () => {
    if (isSpeaking && !isPaused) return;

    if (isPaused) {
      window.speechSynthesis.resume();
      setIsPaused(false);
      return;
    }

    const utter = new SpeechSynthesisUtterance(cleanedText);
    utterRef.current = utter;

    utter.onstart = () => {
      setIsSpeaking(true);
      setIsPaused(false);
    };
    utter.onend = () => {
      setIsSpeaking(false);
      setIsPaused(false);
      utterRef.current = null;
    };
    utter.onerror = () => {
      setIsSpeaking(false);
      setIsPaused(false);
      utterRef.current = null;
    };

    window.speechSynthesis.speak(utter);
  };

  const handlePause = () => {
    if (isSpeaking && !isPaused) {
      window.speechSynthesis.pause();
      setIsPaused(true);
    }
  };

  const handleStop = () => {
    if (isSpeaking || isPaused) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
      setIsPaused(false);
      utterRef.current = null;
    }
  };

  return (
    <div
      style={{
        marginBottom: "1rem",
        textAlign: "left",
        wordBreak: "break-word",
        whiteSpace: "pre-wrap",
        overflowX: "auto",
      }}
    >
      {cleanedText.trim() && (
        <div style={{ marginBottom: "8px" }}>
          <button
            onClick={handleSpeak}
            style={{
              marginRight: 6,
              background: "#4caf50",
              color: "#fff",
              border: "none",
              borderRadius: 4,
              padding: "5px 10px",
              cursor: "pointer",
            }}
          >
            {!isSpeaking || isPaused ? (
              <>
                <FontAwesomeIcon icon={faPlay} /> Play
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faPause} /> Resume
              </>
            )}
          </button>
          <button
            onClick={handlePause}
            style={{
              marginRight: 6,
              background: "#ff9800",
              color: "#fff",
              border: "none",
              borderRadius: 4,
              padding: "5px 10px",
              cursor: "pointer",
            }}
          >
            <FontAwesomeIcon icon={faPause} /> Pause
          </button>
          <button
            onClick={handleStop}
            style={{
              background: "#f44336",
              color: "#fff",
              border: "none",
              borderRadius: 4,
              padding: "5px 10px",
              cursor: "pointer",
            }}
          >
            <FontAwesomeIcon icon={faStop} /> Stop
          </button>
        </div>
      )}

      {/* Render the cleaned Markdown */}
      <MarkdownView text={cleanedText} isDarkMode={isDarkMode} />
    </div>
  );
};

function ChatApp() {
  const [conversations, setConversations] = useState([]);
  const [selectedConvIndex, setSelectedConvIndex] = useState(null);
  const [isNewChat, setIsNewChat] = useState(false);

  const [inputText, setInputText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [rateLimitError, setRateLimitError] = useState(null);

  // Default model: Perplexity
  const [selectedOption, setSelectedOption] = useState("perplexity");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Speech recognition
  const {
    isListening,
    transcript,
    error: speechError,
    setIsListening,
  } = useSpeechRecognition();

  // If we get transcript
  useEffect(() => {
    if (transcript) setInputText(transcript);
  }, [transcript]);

  // Analytics
  useEffect(() => {
    ReactGA.initialize("G-NFKD8XWG73");
    ReactGA.send("pageview");
  }, []);

  const handleUserMessage = (message) => {
    ReactGA.event({
      category: "User",
      action: "Sent a Message",
      label: message,
    });
  };

  const handleChange = (e) => {
    setInputText(e.target.value);
    handleUserMessage(e.target.value);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const ErrorFallback = ({ error }) => {
    const parsedStack = parse(error.stack);
    logErrorToServer(error.message, parsedStack);
    return (
      <div role="alert" style={{ padding: "1rem", color: "red" }}>
        <h2>Something went wrong:</h2>
        <pre>{error.message}</pre>
      </div>
    );
  };

  const logErrorToServer = async (message, stack) => {
    try {
      await fetch("/api/log-error", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ message, stack }),
      });
    } catch (err) {
      console.error("Failed to log error:", err);
    }
  };

  const handleReset = () => {
    setConversations([]);
    setSelectedConvIndex(null);
    setInputText("");
    setIsNewChat(false);
  };

  const handleNewChat = () => {
    setSelectedConvIndex(null);
    setInputText("");
    setIsNewChat(true);
  };

  const apiUrl = import.meta.env.VITE_APP_API_URL || "http://localhost:4000";

  const handleSubmit = async () => {
    if (!inputText.trim()) return;

    setIsLoading(true);
    setError(null);
    setRateLimitError(null);

    let endpoint;
    switch (selectedOption) {
      case "dbrx":
        endpoint = "/api/send-message-dbrx";
        break;
      case "perplexity":
        endpoint = "/api/send-message-perplexity";
        break;
      case "deepseekr1":
        endpoint = "/api/send-message-deepseek";
        break;
      case "qwen":
        endpoint = "/api/send-message-qwen";
        break;
      case "search":
        endpoint = "/api/send-message-search";
        break;
      case "gemini":
        endpoint = "/api/send-message-gemini";
        break;
      case "claude":
        endpoint = "/api/send-message-claude";
        break;
      case "mistral":
        endpoint = "/api/send-message-mistral";
        break;
      case "grok":
        endpoint = "/api/send-message-grok";
        break;
      case "llama":
        endpoint = "/api/send-message-awsbedrock";
        break;
      case "phi":
        endpoint = "/api/send-message-phi";
        break;
      case "nova":
        endpoint = "/api/send-message-nova";
        break;
      default:
        endpoint = "/api/send-message-search";
        break;
    }

    try {
      const response = await fetch(`${apiUrl}${endpoint}`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "text/plain, application/json",
        },
        credentials: "omit",
        body: JSON.stringify({ message: inputText }),
      });

      if (response.status === 429) {
        throw new Error("Rate limit exceeded");
      }
      if (!response.ok) {
        const errorData = await response.text();
        console.error("Server response:", errorData);
        throw new Error(
          `Error sending message: ${response.statusText}. ${errorData}`
        );
      }

      /**
       * STREAMING LOGIC for DeepseekR1, Llama, Qwen, DBRX, Mistral, or Nova
       */
      if (
        selectedOption === "deepseekr1" ||
        selectedOption === "llama" ||
        selectedOption === "qwen" ||
        selectedOption === "mistral" ||
        selectedOption === "dbrx" ||
        selectedOption === "nova"
      ) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let assistantMessage = "";

        // Create new conversation item with empty related questions array
        const newConv = { prompt: inputText, answer: "", relatedQuestions: [] };
        setConversations((prev) => [...prev, newConv]);
        const newIndex = conversations.length;
        setSelectedConvIndex(newIndex);

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const chunk = decoder.decode(value);
          assistantMessage += chunk;

          // Update the conversation with the new chunk
          setConversations((prev) => {
            const updatedConvs = [...prev];
            updatedConvs[newIndex].answer = assistantMessage;
            return updatedConvs;
          });
        }

        // After streaming is complete, generate related questions
        try {
          // Determine which related questions endpoint to use based on the selected model
          let relatedQuestionsEndpoint;
          switch (selectedOption) {
            case "deepseekr1":
              relatedQuestionsEndpoint = "/api/related-questions-deepseek";
              break;
            case "llama":
              relatedQuestionsEndpoint = "/api/related-questions-llama";
              break;
            case "qwen":
              relatedQuestionsEndpoint = "/api/related-questions-qwen";
              break;
            case "mistral":
              relatedQuestionsEndpoint = "/api/related-questions-mistral";
              break;
            case "dbrx":
              relatedQuestionsEndpoint = "/api/related-questions-dbrx";
              break;
            case "nova":
              relatedQuestionsEndpoint = "/api/related-questions-nova";
              break;
            default:
              relatedQuestionsEndpoint = "/api/send-message-search"; // Fallback to OpenAI
          }
          
          // Use the appropriate endpoint to generate related questions
          const relatedQuestionsResponse = await fetch(`${apiUrl}${relatedQuestionsEndpoint}`, {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            credentials: "omit",
            body: JSON.stringify({ 
              originalQuestion: inputText,
              answer: assistantMessage
            }),
          });

          if (relatedQuestionsResponse.ok) {
            const relatedQuestionsData = await relatedQuestionsResponse.json();
            let relatedQuestions = [];
            
            // Try to parse the response as JSON
            try {
              if (typeof relatedQuestionsData.response === 'string') {
                // Remove any markdown code block formatting if present
                const jsonText = relatedQuestionsData.response.replace(/```json\n|\n```|```/g, '');
                relatedQuestions = JSON.parse(jsonText);
              } else if (Array.isArray(relatedQuestionsData.relatedQuestions)) {
                relatedQuestions = relatedQuestionsData.relatedQuestions;
              }
              
              // Ensure we have an array of strings
              if (!Array.isArray(relatedQuestions)) {
                relatedQuestions = [];
              }
              
              // Update the conversation with related questions
              setConversations((prev) => {
                const updatedConvs = [...prev];
                updatedConvs[newIndex].relatedQuestions = relatedQuestions;
                return updatedConvs;
              });
            } catch (error) {
              console.error("Error parsing related questions:", error);
            }
          }
        } catch (error) {
          console.error("Error generating related questions:", error);
        }

        setInputText("");
        setIsNewChat(false);
      } else {
        // NON-STREAMING LOGIC (e.g., Perplexity, Grok, ChatGPT, Gemini, Claude, Phi, etc.)
        let data;
        try {
          data = await response.json();
        } catch (err) {
          data = await response.text();
        }

        let assistantMessage = "";
        if (typeof data === "string") {
          assistantMessage = data;
        } else if (data?.content) {
          assistantMessage = data.content[0]?.text || "No response.";
        } else if (data?.response) {
          assistantMessage = data.response;
        } else {
          assistantMessage = "No response from server.";
        }

        // Check if we have related questions and citations in the response
const relatedQuestions = data?.relatedQuestions || [];
const citations = data?.citations || [];
const newConv = { 
  prompt: inputText, 
  answer: assistantMessage,
  relatedQuestions: relatedQuestions,
  citations: citations
};
        setConversations((prev) => [...prev, newConv]);
        const newIndex = conversations.length;
        setSelectedConvIndex(newIndex);

        setInputText("");
        setIsNewChat(false);
      }
    } catch (err) {
      console.error("Error:", err);
      if (err.message === "Rate limit exceeded") {
        setRateLimitError(
          "You have exceeded the max number of requests today."
        );
      } else {
        setError("Failed to get response from the server.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleShare = (text) => {
    if (navigator.share) {
      navigator.share({ title: "AI Response", text }).catch((err) => {
        console.error("Error sharing:", err);
      });
    } else {
      navigator.clipboard.writeText(text).then(() => {
        alert("AI response copied to clipboard!");
      });
    }
  };

  const activeConversation =
    selectedConvIndex !== null ? conversations[selectedConvIndex] : null;

  const mainBgColor = isDarkMode ? "#222" : "#fff";
  const mainTextColor = isDarkMode ? "#f2f2f2" : "#000";
  const sidebarBgColor = isDarkMode ? "#333" : "#f5f5f5";
  const sidebarBorderColor = isDarkMode ? "#666" : "#ddd";
  const sidebarSelectedColor = isDarkMode ? "#555" : "#ddd";
  const sidebarUnselectedColor = isDarkMode ? "#444" : "#fff";

  // Check for mobile device on mount and window resize
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Initial check
    checkIfMobile();

    // Listen for window resize
    window.addEventListener("resize", checkIfMobile);

    // Cleanup
    return () => window.removeEventListener("resize", checkIfMobile);
  }, []);

  // Close sidebar automatically on mobile
  useEffect(() => {
    if (isMobile) {
      setSidebarOpen(false);
    }
  }, [isMobile]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div
        style={{
          display: "flex",
          height: "100vh",
          backgroundColor: mainBgColor,
          color: mainTextColor,
          overflow: "hidden",
        }}
      >
        {/* SIDEBAR - hide completely on mobile */}
        {!isMobile && (
          <div
            style={{
              width: sidebarOpen ? "250px" : "50px",
              backgroundColor: sidebarBgColor,
              borderRight: `1px solid ${sidebarBorderColor}`,
              display: "flex",
              flexDirection: "column",
              transition: "width 0.3s ease",
              overflowX: "hidden",
            }}
          >
            <button
              onClick={() => setSidebarOpen(!sidebarOpen)}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                padding: "0.5rem",
                color: isDarkMode ? "#fff" : "#000",
                textAlign: "left",
              }}
            >
              {sidebarOpen ? (
                <FontAwesomeIcon icon={faChevronLeft} />
              ) : (
                <FontAwesomeIcon icon={faListUl} />
              )}
            </button>

            {sidebarOpen && (
              <div
                style={{
                  flex: 1,
                  overflowY: "auto",
                  padding: "0 0.5rem 1rem",
                  minWidth: 0,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "1rem",
                    marginTop: "0.5rem",
                  }}
                >
                  <h3>History</h3>
                  <button
                    onClick={handleNewChat}
                    style={{
                      background: "#007BFF",
                      border: "none",
                      cursor: "pointer",
                      padding: "0.5rem 1rem",
                      borderRadius: "5px",
                      color: "#fff",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    New Chat
                  </button>
                </div>

                {conversations.map((conv, idx) => (
                  <div
                    key={idx}
                    onClick={() => {
                      setSelectedConvIndex(idx);
                      setIsNewChat(false);
                    }}
                    style={{
                      marginBottom: "8px",
                      padding: "8px",
                      borderRadius: "5px",
                      backgroundColor:
                        idx === selectedConvIndex
                          ? sidebarSelectedColor
                          : sidebarUnselectedColor,
                      cursor: "pointer",
                      border: `1px solid ${isDarkMode ? "#666" : "#ccc"}`,
                    }}
                    title="Click to show the full conversation"
                  >
                    {conv.prompt.slice(0, 70)}
                    {conv.prompt.length > 70 && "..."}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {/* MAIN CONTENT */}
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          {/* HEADER */}
          <div
            style={{
              width: "100%",
              padding: "10px",
              position: "relative",
              borderBottom: "none",
              boxSizing: "border-box",
            }}
          >
            <Header />
            {/* Dark Mode Toggle at top-right */}
            {!isMobile && (
              <button
                onClick={() => setIsDarkMode(!isDarkMode)}
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "20px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  color: isDarkMode ? "#fff" : "#000",
                  fontSize: "1.2rem",
                }}
                title="Toggle Dark Mode"
              >
                {isDarkMode ? (
                  <FontAwesomeIcon icon={faSun} />
                ) : (
                  <FontAwesomeIcon icon={faMoon} />
                )}
              </button>
            )}
            {/* Mobile-only dark mode toggle */}
            {isMobile && (
              <div
                style={{
                  position: "absolute",
                  right: "20px",
                  top: "20px",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <button
                  onClick={() => setIsDarkMode(!isDarkMode)}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    color: isDarkMode ? "#fff" : "#000",
                    fontSize: "1.2rem",
                  }}
                  title="Toggle Dark Mode"
                >
                  {isDarkMode ? (
                    <FontAwesomeIcon icon={faSun} />
                  ) : (
                    <FontAwesomeIcon icon={faMoon} />
                  )}
                </button>
              </div>
            )}
          </div>

          {/* BODY */}
          <div
            style={{
              width: "100%",
              padding: "1rem 2rem",
              boxSizing: "border-box",
              overflowX: "hidden",
            }}
          >
            {isNewChat ? (
              <div style={{ marginBottom: "1rem", opacity: 0.8 }}>
                <p>Starting a brand new chat!</p>
                <p>Type your question below and click “Send.”</p>
              </div>
            ) : activeConversation ? (
              <div style={{ marginBottom: "1rem", textAlign: "left" }}>
                <span>You:</span>
                <p style={{ margin: "8px 0", fontSize: "18px" }}>
                  {activeConversation.prompt}
                </p>
                <span>Assistant:</span>
                <BotReply
                  text={activeConversation.answer}
                  isDarkMode={isDarkMode}
                />
                {activeConversation.answer && (
                  <div>
                    <button
                      onClick={() => handleShare(activeConversation.answer)}
                      style={{
                        marginTop: "5px",
                        background: "#2196f3",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        padding: "6px 10px",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon icon={faShareAlt} /> Share
                    </button>
                    
                    {/* Related Questions Section */}
                    {activeConversation.relatedQuestions && activeConversation.relatedQuestions.length > 0 && (
                      <div 
                        style={{
                          marginTop: "20px",
                          borderTop: `1px solid ${isDarkMode ? "#444" : "#ddd"}`,
                          paddingTop: "15px",
                        }}
                      >
                        <h4 style={{ marginBottom: "10px" }}>Related Questions</h4>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                          }}
                        >
                          {activeConversation.relatedQuestions.map((question, idx) => (
                            <button
                              key={idx}
                              onClick={() => {
                                setInputText(question);
                                // Scroll to the input area
                                document.querySelector('.askQuestion').scrollIntoView({ behavior: 'smooth' });
                                // Focus on the input
                                document.querySelector('.askQuestion').focus();
                              }}
                              style={{
                                textAlign: "left",
                                padding: "10px 15px",
                                backgroundColor: isDarkMode ? "#333" : "#f5f5f5",
                                color: isDarkMode ? "#fff" : "#333",
                                border: `1px solid ${isDarkMode ? "#444" : "#ddd"}`,
                                borderRadius: "4px",
                                cursor: "pointer",
                                fontSize: "16px",
                                transition: "background-color 0.2s",
                                boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
                              }}
                              onMouseOver={(e) => {
                                e.currentTarget.style.backgroundColor = isDarkMode ? "#444" : "#e9e9e9";
                              }}
                              onMouseOut={(e) => {
                                e.currentTarget.style.backgroundColor = isDarkMode ? "#333" : "#f5f5f5";
                              }}
                            >
                              {question}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                    
                    {/* References Section */}
                    {activeConversation.citations && activeConversation.citations.length > 0 && (
                      <div 
                        style={{
                          marginTop: "20px",
                          borderTop: `1px solid ${isDarkMode ? "#444" : "#ddd"}`,
                          paddingTop: "15px",
                        }}
                      >
                        <h4 style={{ marginBottom: "10px" }}>References ({activeConversation.citations.length})</h4>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "10px",
                          }}
                        >
                          {activeConversation.citations.map((citation, idx) => {
                            // Determine icon based on domain
                            let icon = null;
                            let bgColor = "#f0f0f0";
                            let textColor = "#000";
                            
                            if (citation.domain.toLowerCase() === "youtube") {
                              bgColor = "#FF0000";
                              textColor = "#fff";
                            } else if (citation.domain.toLowerCase() === "bbc") {
                              bgColor = "#000000";
                              textColor = "#fff";
                            } else if (citation.domain.toLowerCase() === "wikipedia") {
                              bgColor = "#f5f5f5";
                              textColor = "#000";
                            } else if (citation.domain.toLowerCase() === "forbes") {
                              bgColor = "#000000";
                              textColor = "#fff";
                            } else if (citation.domain.toLowerCase() === "royal") {
                              bgColor = "#9D2235";
                              textColor = "#fff";
                            } else if (citation.domain.toLowerCase() === "tesla") {
                              bgColor = "#cc0000";
                              textColor = "#fff";
                            } else if (citation.domain.toLowerCase() === "usa") {
                              bgColor = "#1877F2";
                              textColor = "#fff";
                            }
                            
                            return (
                              <a
                                key={idx}
                                href={citation.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                  padding: "8px 12px",
                                  backgroundColor: isDarkMode ? "#333" : "#f5f5f5",
                                  color: isDarkMode ? "#fff" : "#333",
                                  border: `1px solid ${isDarkMode ? "#444" : "#ddd"}`,
                                  borderRadius: "4px",
                                  textDecoration: "none",
                                  fontSize: "14px",
                                  transition: "background-color 0.2s",
                                }}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.backgroundColor = isDarkMode ? "#444" : "#e9e9e9";
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.backgroundColor = isDarkMode ? "#333" : "#f5f5f5";
                                }}
                              >
                                <span 
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "24px",
                                    height: "24px",
                                    backgroundColor: bgColor,
                                    color: textColor,
                                    borderRadius: "4px",
                                    fontWeight: "bold",
                                    fontSize: "12px",
                                  }}
                                >
                                  {idx + 1}
                                </span>
                                {citation.domain}
                              </a>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : null}

            {/* AI model dropdown */}
            <div style={{ marginBottom: "10px", marginTop: "20px" }}>
              <label htmlFor="modelSelect" style={{ marginRight: "10px" }}>
                Select a model:
              </label>
              <select
                id="modelSelect"
                value={selectedOption}
                onChange={handleOptionChange}
                style={{
                  padding: "10px",
                  backgroundColor: isDarkMode ? "#333" : "#000",
                  color: "#fff",
                  borderRadius: "5px",
                  border: "none",
                  fontSize: "16px",
                  minWidth: "200px",
                }}
              >
                {/* REORDERED OPTIONS: 
                    1) Perplexity
                    2) DeepSeek R1
                    3) Qwen AI
                    4) ChatGPT
                    5) Google Gemini
                    6) Claude AI
                    7) xAI Grok
                    8) Llama 3
                    9) Phi 3.5 (last)
                    10) Mistral AI
                */}
                <option value="perplexity">Perplexity</option>
                <option value="deepseekr1">DeepSeek R1</option>
                <option value="qwen">Qwen AI by Alibaba</option>
                <option value="search">ChatGPT</option>
                <option value="gemini">Google Gemini</option>
                <option value="claude">Claude AI</option>
                <option value="mistral">Mistral AI</option>
                <option value="grok">xAI Grok</option>
                <option value="dbrx">DBRX by DataBricks</option>
                <option value="llama">Llama 3</option>
                <option value="nova">Amazon Nova Pro</option>
                <option value="phi">Phi 3.5 by Microsoft</option>
              </select>
            </div>

            {/* Prompt + mic */}
            <div style={{ width: "100%", position: "relative" }}>
              <textarea
                className="askQuestion"
                placeholder="Ask a question..."
                style={{
                  display: "block",
                  boxSizing: "border-box",
                  width: "100%",
                  minHeight: "150px",
                  paddingRight: "50px",
                  backgroundColor: isDarkMode ? "#333" : "#fff",
                  color: isDarkMode ? "#fff" : "#000",
                  border: `1px solid ${isDarkMode ? "#555" : "#ccc"}`,
                  fontSize: "16px",
                  fontFamily:
                    "OpenAI, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
                  lineHeight: 1.5,
                  borderRadius: "4px",
                }}
                value={inputText}
                onChange={handleChange}
              />
              <button
                type="button"
                style={micButtonStyle(isListening)}
                onClick={() => setIsListening(!isListening)}
              >
                <FontAwesomeIcon icon={faMicrophone} />
              </button>
            </div>

            {error && <p style={{ color: "red" }}>{error}</p>}
            {rateLimitError && <p style={{ color: "red" }}>{rateLimitError}</p>}
            {speechError && <p style={{ color: "red" }}>{speechError}</p>}

            <div style={{ marginTop: "10px" }}>
              <button
                type="button"
                onClick={handleSubmit}
                style={{
                  backgroundColor: "#007BFF",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  padding: "8px 12px",
                  cursor: "pointer",
                  marginRight: "8px",
                }}
              >
                Send <FontAwesomeIcon icon={faPaperPlane} />
              </button>
              <button
                type="button"
                onClick={handleReset}
                style={{
                  backgroundColor: isDarkMode ? "#555" : "#ddd",
                  border: "none",
                  borderRadius: "4px",
                  padding: "8px 12px",
                  cursor: "pointer",
                }}
              >
                Reset
              </button>
            </div>

            {/* AI Tools */}
            <div
              style={{
                marginTop: "20px",
                borderTop: `1px solid ${isDarkMode ? "#555" : "#ccc"}`,
                paddingTop: "10px",
              }}
            >
              <h4>AI Tools</h4>
              <div
                style={{
                  margin: "10px 0",
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                  gap: "10px",
                  textAlign: "center",
                }}
              >
                <a
                  href="https://record.my-docs.ai"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#8e44ad",
                    textDecoration: "none",
                    fontWeight: "bold",
                    fontSize: "16px",
                    display: "inline-block",
                    padding: "5px 0",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faVideo}
                    style={{ marginRight: "5px" }}
                  />
                  Screen Recorder
                </a>
                <a
                  href="https://talk.my-docs.ai/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#8e44ad",
                    textDecoration: "none",
                    fontWeight: "bold",
                    fontSize: "16px",
                    display: "inline-block",
                    padding: "5px 0",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faComments}
                    style={{ marginRight: "5px" }}
                  />
                  Talk2Text AI
                </a>
                <a
                  href="http://translate.my-docs.ai/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#8e44ad",
                    textDecoration: "none",
                    fontWeight: "bold",
                    fontSize: "16px",
                    display: "inline-block",
                    padding: "5px 0",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faGlobe}
                    style={{ marginRight: "5px" }}
                  />
                  Translate AI
                </a>
                <a
                  href="https://pdftomp3.my-docs.ai"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#8e44ad",
                    textDecoration: "none",
                    fontWeight: "bold",
                    fontSize: "16px",
                    display: "inline-block",
                    padding: "5px 0",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faVolumeHigh}
                    style={{ marginRight: "5px" }}
                  />
                  PDF to MP3
                </a>
              </div>
            </div>
          </div>

          {/* FOOTER */}
          <footer
            style={{
              flexShrink: 0,
              width: "100%",
              textAlign: "center",
              padding: "10px",
              borderTop: `1px solid ${isDarkMode ? "#444" : "#ccc"}`,
            }}
          >
            <p style={{ margin: 0 }}>
              Made in <span style={{ color: "red" }}>❤️</span> with AI by{" "}
              <a
                href="https://www.linkedin.com/in/selvaonline/"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "#007BFF",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                Selvakumar Murugesan
              </a>
            </p>
          </footer>
        </div>
      </div>

      {/* 
         Spinner Overlay 
         -- Hide for streaming models (DeepSeek R1, Llama, Qwen, DBRX, Mistral)
         -- Show for Nova Pro since it needs the spinner
      */}
      {selectedOption !== "deepseekr1" &&
        selectedOption !== "llama" &&
        selectedOption !== "qwen" &&
        selectedOption !== "mistral" &&
        selectedOption !== "dbrx" &&
        isLoading && (
          <div
            style={{
              position: "fixed",
              inset: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(255,255,255,0.7)",
              zIndex: 9999,
            }}
          >
            <Spinner />
          </div>
        )}

      {/* Mic pulse animation */}
      <style>{`
        @keyframes micPulse {
          0%   { background-color: #333; }
          50%  { background-color: red;   }
          100% { background-color: #333; }
        }
      `}</style>
    </ErrorBoundary>
  );
}

export default ChatApp;
