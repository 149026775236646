import React from "react";

const Header = () => {
  return (
    <header className="chat-header">
      <a href="https://my-docs.ai" rel="noreferrer">
        <img
          src="/mydocs-logo.png"
          style={{ width: "80px", height: "49px" }}
          alt="my-docs.ai"
        />
      </a>
    </header>
  );
};

export default Header;
